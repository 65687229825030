<template>


    <dashBusinessman v-if="$store.state.auth.userType === 'businessman'" />
    <dashSuperAdmin v-else-if="HAS_ROLES('ROLE_ULTRAADMIN')"  />
    <dash-main-courses v-else />



  </template>

<script>

import DashMainCourses from "@/components/pages/dashboard/student/DashMainCourses";
import dashSuperAdmin from "@/components/pages/dashboard/dash/dashSuperAdmin";
import dashBusinessman from "@/components/pages/dashboard/dash/dashBusinessman";
export default {
  name: "DashIndex",
  components : {
    dashBusinessman,
    dashSuperAdmin,
    DashMainCourses
  }

}
</script>

<style scoped>

</style>