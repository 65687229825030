<template>
  <dash-page :title="$t('Dashboard')"
             :subtitle="$t('WelcomeBackMaster')"
             icon="mdi-view-dashboard-outline"
             side-admin-manager
             v-if="$store.state.auth.userType === 'superadmin'">

    <template #default>

      <div>

      </div>
      <v-row class="my-3">
        <v-col  cols="12" xs="12" sm="12" md="12" lg="6" xl="6"  >
          <v-sheet dark class="wsRoundedHalf pa-6 fill-height" :color="wsDARK" style="background: linear-gradient(25deg, rgba(47, 84, 152, 0.76), rgba(157, 60, 0, 0.57))">
            <div class="d-flex align-center">
              <v-icon  class="mr-5">mdi-briefcase-outline</v-icon>
              <div>
                <h6 class="font-weight-light">{{ $t('Нові реєстрації') }}:</h6>
                <h4>{{ dashobard.business_total }}</h4>
              </div>
            </div>
            <div class="d-flex mt-3 align-center">
              <v-icon class="mr-5">mdi-book-plus-multiple</v-icon>

              <div>
                <h6 class="font-weight-light">{{ $t('Нові заявки') }}:</h6>
                <h4>{{ dashobard.lead_total }}</h4>
              </div>
            </div>
          </v-sheet>
        </v-col>
        <v-col  cols="12" xs="12" sm="12" md="12" lg="6" xl="6"  >
          <v-sheet dark class="wsRoundedHalf pa-6 fill-height d-flex align-center" :color="wsDARKLIGHT">
            <div>
              <div class="d-flex">
                <v-icon  class="mr-5">mdi-briefcase-outline</v-icon>
                <div>
                  <h6 class="font-weight-light">{{ $t('Реєстрації сьогодні') }}</h6>
                  <h4><v-icon small>mdi-plus</v-icon>{{ dashobard.new_businesses_today }}</h4>
                </div>
              </div>
              <div class="d-flex mt-3">
                <v-icon class="mr-5">mdi-book-plus-multiple</v-icon>
                <div>
                  <h6 class="font-weight-light">{{ $t('Заявки сьогодні') }}</h6>
                  <h4><v-icon small>mdi-plus</v-icon>{{ dashobard.new_leads_today}}</h4>
                </div>
              </div>
            </div>
          </v-sheet>
        </v-col>
      </v-row>

      <v-sheet class="pa-6 mt-6 wsRoundedHalf">
        <h4 class="mb-3">Реєстрації за місяць:</h4>
        <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
      </v-sheet>

    </template>


  </dash-page>
</template>

<script>
import {mapActions} from "vuex";
import apexchart from "vue-apexcharts";

export default {
  name: "dashSuperAdmin",
  components : {
    apexchart
  },
  data() {
    return {
      dashobard : {},
      graphicValues : [],
      graphicLabels : [],
    }
  },
  computed : {
    series() {
      return [{
        name: "Нові реєстрації",
        data: this.graphicValues
      }]
    } ,
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        colors : [this.wsACCENT],
        dataLabels: {
          enabled: true
        },
        toolbar : {
          enabled : false
        },
        stroke: {
          curve: 'straight'
        },

        grid: {
          row: {
            colors: [this.wsLIGHTCARD, this.wsBACKGROUND], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: this.graphicLabels,
        },
      }
    }
  },
  methods : {
    ...mapActions('adminCrmSystem', [
      'ADMIN_GET_DASHBOARD',
    ] ),
  },
  async mounted() {
    this.dashobard = await this.ADMIN_GET_DASHBOARD()
    this.graphicValues = this.dashobard.graphic_business_values
    this.graphicLabels = this.dashobard.graphic_business_labels
  }
}
</script>

<style scoped>

</style>